.modal {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 999;

	& > * {
		z-index: 1;
	}

	.bg {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(0, 0, 0, 0.6);
		z-index: 0;
	}

	.modal-content {
		background-color: #f5f5f5;
		border-radius: 6px;
		overflow: auto;

		&.fullscreen {
			position: absolute;
			top: 5%;
			bottom: 5%;
			left: 5%;
			right: 5%;
		}
	}
}
