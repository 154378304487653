app-geometrics-container,
app-geometrics-kpis {
	.row {
		display: block;
		width: 100%;
	}

	.answers {
		justify-content: flex-start;
	}

	.group {
		& > .label {
			color: #666;
			font-size: 1.2rem;
		}
	}

	.groups {
		margin: 0;

		.group {
			margin: 20px 0 20px;
		}
	}

	.groups.flex {
		display: flex;
		justify-content: space-between;

		.group {
			width: calc(33% - 42px);
		}

		.answer {
			width: 100%;
		}
	}

	.answers {
		display: flex;
		flex-wrap: wrap;

		.answer {
			width: calc(33% - 42px);
			min-width: 160px;
			// border: 1px solid rgba(0, 0, 0, 0.2);
			border-radius: 6px;
			margin: 10px 10px 10px 0;
			padding: 15px 10px;
			cursor: pointer;
			background: var(--color-step-50);
			color: var(--color-secondary);
			user-select: none;
			transition: all 300ms;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			text-align: left;
			position: relative;

			.bg {
				background: rgba(255, 0, 0, 0.5);
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				z-index: 5;
			}

			.info {
				z-index: 1;
				* {
					margin: 0;
				}
			}

			.label {
				margin-top: 0;
				margin-bottom: 5px !important;
				font-size: 1.6rem !important;
				padding: 0 !important;
				font-weight: 500;

				&.large {
					font-size: 1.9rem !important;
				}
			}

			.caption,
			.category {
				font-size: 1.3rem;
				color: #666;
				margin: 0;
			}

			.category {
				margin-bottom: 10px;
				font-weight: bold;
				font-size: 0.8rem;
			}

			.option {
				position: relative;
				z-index: 10;
				transform: translate3d(0, 12px, 0) scale(0.9);

				.mat-form-field-flex {
					background: #f6f6f6;
				}
			}

			&.withOption {
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				padding: 10px 20px;

				.option {
					display: none;
				}

				.info {
					text-align: left;
				}

				&.selected {
					.option {
						display: block;
					}
				}
			}

			&.kpi {
				width: 100%;
				margin: 0 0 10px 0;

				.category {
					position: absolute;
					top: 16px;
					right: 12px;
					font-size: 1.3rem;
					color: var(--color-secondary);
					opacity: 0.6;
				}
			}
		}

		&.flex-2 {
			.answer {
				width: calc(50% - 42px);
			}
		}

		&.flex-1 {
			.answer {
				width: 100%;
			}
		}
	}
}

app-geometrics-kpis {
	width: 100%;
}

/** Overrides for Geometrics npm module **/
geometrics-app {
	.logo {
		display: none;
	}

	.sidebar {
		position: absolute !important;
	}

	.groups {
		min-width: unset !important;
	}

	.final-report {
		padding-right: 20px !important;

		.label {
			font-size: 2.5rem;
			margin-top: 0;
			margin-bottom: 6px;
			padding: 0 12px;
		}
	}

	@media only screen and (max-width: 992px) {
		.final-report {
			.buttons {
				flex-wrap: wrap;
				gap: 10px;

				button {
					width: 100%;
				}
			}
		}

		.answer {
			width: 100% !important;
		}

		.group {
			width: 100% !important;
			margin: 10px 0 !important;
			padding: 0 10px !important;
		}
	}
}
