/**
*	INCOMPLETE:  This is currently only working for one use case, needs to be blown out for other positioning, etc.
**/

$startOffset: -20px;
$endOffset: -10px;

.popover-wrapper {
	position: relative;
	display: inline-block;

	&.right {
		.popover-content {
			left: auto;
			right: 0;
			transform: translate(0%, $startOffset);

			&:before {
				right: 20px;
			}
		}

		&:hover {
			.popover-content {
				transform: translate(0%, $endOffset);
			}
		}
	}
}

.popover-content {
	opacity: 0;
	visibility: hidden;
	position: absolute;
	left: 50%;
	bottom: 100%;
	transform: translate(-50%, $startOffset);
	background-color: white;
	padding: 1.5rem;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.36);
	width: auto;
	border-radius: 6px;
}

.popover-wrapper:hover .popover-content {
	z-index: 10;
	opacity: 1;
	visibility: visible;
	transform: translate(-50%, $endOffset);
	transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}

.popover-content:before {
	position: absolute;
	z-index: -1;
	content: '';
	right: calc(50% - 10px);
	bottom: -8px;
	border-style: solid;
	border-width: 10px 10px 0 10px;
	border-color: white transparent transparent transparent;
	transition-duration: 0.3s;
	transition-property: transform;
}
