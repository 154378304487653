.row {
	width: 100%;
}

.half-row {
	width: 50%;
}

.faded {
	opacity: 0.6;
}

.pointer {
	cursor: pointer;
}

.really-faded {
	opacity: 0.2;
}

.flex {
	display: flex;

	.right {
		margin-left: auto;
		text-align: right;
	}
}

.flex-column {
	flex-direction: column;
}

.flex-1 {
	flex: 1;
}

.flex-2 {
	flex: 2;
}

.flex-3 {
	flex: 3;
}

.align-items-center {
	align-items: center;
}

.align-items-end {
	align-items: flex-end;
}

.margin-left-auto {
	margin-left: auto !important;
}

.overflow-hidden {
	overflow: hidden;
}

.ellipsis {
	text-overflow: ellipsis; /* enables ellipsis */
	white-space: nowrap; /* keeps the text in a single line */
	overflow: hidden; /* keeps the element from overflowing its parent */
}

.no-margin {
	margin: 0 !important;
}

.no-padding {
	padding: 0 !important;
}

.margin-top {
	margin-top: 10px !important;
}

.margin-right {
	margin-right: 10px !important;
}

.margin-left {
	margin-left: 10px !important;
}

.margin-bottom {
	margin-bottom: 10px !important;
}

.text-center {
	text-align: center !important;
}

.text-secondary {
	color: var(--color-secondary) !important;
}

.text-danger {
	color: var(--color-danger) !important;
}

.text-success {
	color: var(--color-success) !important;
}

.capitalize {
	text-transform: capitalize !important;
}

.disabled-link {
	text-decoration: none !important;
	cursor: default !important;
}

.no-decoration {
	text-decoration: none !important;
}

.no-height {
	min-height: 0 !important;
	height: 0 !important;
	max-height: 0 !important;
}

.hidden {
	display: none !important;
}

.text-align-left {
	text-align: left !important;
}

.text-align-right {
	text-align: right !important;
}

.max-height-fill {
	max-height: 100% !important;
}

.display-block {
	display: block !important;
}

.line-clamp {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.sticky-bottom {
	position: sticky !important;
	bottom: 0 !important;
}

.no-border-radius {
	border-radius: 0 !important;
}

.text-black {
	color: black !important;
}

.link {
	color: var(--color-primary);
	text-decoration: underline;
}

.background-secondary {
	background-color: var(--color-secondary) !important;
}

.background-tertiary {
	background-color: var(--color-step-400) !important;
}

.background-transparent {
	background-color: transparent !important;
}

/**
*	Comma class:  Adds commas after a list of spans
**/

.with-comma:not(:first-child) {
	margin-left: -0.3em;
}

.with-comma:first-child:empty ~ .with-comma:not(:empty) {
	margin-left: 0;
}

.with-comma:first-child:empty ~ .with-comma:not(:empty) ~ .with-comma:not(:empty) {
	margin-left: -0.3em;
}

.with-comma:empty {
	display: none;
}

.with-comma:not(:first-child):before {
	content: ", ";
}

.with-comma:empty + .with-comma:not(:empty):before {
	content: "";
}

.with-comma:not(:empty) ~ .with-comma:empty + .with-comma:not(:empty):before {
	content: ", ";
}

.pre-wrap {
	white-space: pre-wrap;
}

.span-1 {
	grid-column: span 1 !important;
}

.span-2 {
	grid-column: span 2 !important;
}

.span-3 {
	grid-column: span 3 !important;
}

.span-4 {
	grid-column: span 4 !important;
}

.span-5 {
	grid-column: span 5 !important;
}

.span-6 {
	grid-column: span 6 !important;
}

.span-7 {
	grid-column: span 7 !important;
}

.span-8 {
	grid-column: span 8 !important;
}

.span-9 {
	grid-column: span 9 !important;
}

.span-10 {
	grid-column: span 10 !important;
}

.span-11 {
	grid-column: span 11 !important;
}

.span-12 {
	grid-column: span 12 !important;
}
