$table-blue-150: #caced6;
$table-blue-200: #b6bac3;

$level-0-bg: var(--color-secondary-step-150);
$level-1-bg: white;
$level-2-bg: var(--color-secondary-step-25);
$level-3-bg: var(--color-secondary-step-100);

.gstc-wrapper {
	border-radius: 6px;
	overflow: hidden;
}

// Important heights are due to supporting the calendar plugin
table.mat-table tr.mat-row,
.gstc__list-column-row {
	// NOTE: If you change these, you MUST also change in table-calendar.component.ts
	--level-0-height: 55px;
	--level-1-height: 48px;
	--level-2-height: 40px;
	--level-3-height: 32px;
	--expander-size: 14px;

	color: var(--color-secondary);

	.btn {
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}

	/* Wrapper for data in the column item */
	.column-data {
		height: 100%;
	}

	// Don't show the calendar expander unless its actually a group
	&:not(.hasChildren) {
		.gstc__list-column-row-expander-toggle {
			display: none;
		}
	}

	.gstc__list-column-row-content {
		.select-button {
			i {
				line-height: 2rem;
			}
		}
	}

	&.level-0 {
		height: var(--level-0-height);
		border-right: none;

		td.mat-cell,
		.gstc__list-column-row-expander,
		.gstc__list-column-row-content {
			border-right: none;
			background: #e5e6e8;
			font-weight: 700;

			span {
				font-weight: 700;
			}

			&:first-of-type {
				font-family: var(--font-heading);
			}
		}

		.mat-column-action {
			.bg {
				background: #e5e6e8 !important;
				z-index: 0;
			}

			i {
				position: relative;
				z-index: 1;
			}
		}
	}

	&.level-1 {
		height: var(--level-1-height);

		td.mat-cell,
		.gstc__list-column-row-expander,
		.gstc__list-column-row-content {
			font-weight: 500;
			background: white;
		}

		th.mat-header-cell:first-of-type,
		td.mat-cell:first-of-type,
		td.mat-footer-cell:first-of-type {
			padding-left: 33px;
		}

		.mat-column-action {
			.bg {
				background: $level-1-bg !important;
			}
		}

		&:hover {
			td.mat-cell:not(.mat-column-action) {
				background: var(--color-secondary-step-25);
			}
		}
	}

	&.level-2 {
		height: var(--level-2-height);

		td.mat-cell,
		.gstc__list-column-row-expander,
		.gstc__list-column-row-content {
			font-size: 0.8em;
			font-weight: 300;
			background: $level-2-bg;
		}

		.gstc__list-column-row-content {
			font-size: 0.9em;
		}

		th.mat-header-cell:first-of-type,
		td.mat-cell:first-of-type,
		td.mat-footer-cell:first-of-type {
			padding-left: 51px;
		}

		.mat-column-action {
			.bg {
				background: $level-2-bg !important;
			}
		}

		&:hover {
			td.mat-cell:not(.mat-column-action) {
				background: var(--color-secondary-step-50);
			}
		}
	}

	&.level-3 {
		height: var(--level-3-height);

		td.mat-cell,
		.gstc__list-column-row-expander,
		.gstc__list-column-row-content {
			font-size: 0.8em;
			font-weight: 300;
			background: $level-3-bg;
		}

		.gstc__list-column-row-content {
			font-size: 0.9em;
		}

		th.mat-header-cell:first-of-type,
		td.mat-cell:first-of-type,
		td.mat-footer-cell:first-of-type {
			padding-left: 71px;
		}

		.mat-column-action {
			.bg {
				background: $level-3-bg !important;
			}
		}

		&:hover {
			td.mat-cell:not(.mat-column-action) {
				background: var(--color-secondary-step-150);
			}
		}
	}

	// Types

	&.pagination {
		td.mat-cell,
		.gstc__list-column-row-expander,
		.gstc__list-column-row-content {
			font-family: var(--font-heading);
			opacity: 0.6;

			&.mat-column-action {
				background: inherit;
			}

			&:first-of-type {
				border-right: none;
			}
		}
	}

	.value-context {
		opacity: 0.6;
	}

	.column-badge {
		text-transform: capitalize;
		line-height: 1.7rem;
	}
}

/** Add a small border on the level 2 items */
.gstc__list-column-row.level-2 {
	border-bottom: 1px solid var(--color-step-100);
}

// Calendar: The grid underneath timeline items
.gstc__chart-timeline-items-row {
	.item-label,
	.item-description {
		text-shadow: 1px 1px black;
	}

	&.level-0 {
		background: #e5e6e8;
	}

	&.level-1 {
		background: $level-1-bg;
		border-bottom: 1px solid #f2f4f5;
		.gstc__chart-timeline-items-row-item-label {
			.item-label {
				font-weight: 300;
				font-size: 1.2rem;
			}

			.item-description {
				font-size: 1.2rem;
				line-height: 1.2rem;
				margin-bottom: 0;
				font-weight: 400;
			}
		}
	}

	&.level-2,
	&.level-3 {
		background: $level-2-bg;
		border-bottom: 1px solid var(--color-step-100);

		.gstc__chart-timeline-items-row-item-label {
			.item-label {
				font-weight: 300;
				font-size: 1.1rem;
				line-height: 1.1rem;
			}

			.item-description {
				font-size: 1.2rem;
				line-height: 1.2rem;
				font-weight: 400;
			}
		}
	}
}

.gstc__chart-timeline-items-row-item {
	border-radius: 6px;

	// GTSC Chart items styles {
	.gstc__chart-timeline-items-row-item-label {
		margin: 0 10px;
		align-items: center;

		.item-description {
			span {
				margin-right: 8px;
			}
		}
	}

	.btn {
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}

	&.color-0 {
		background: var(--chart-color-0);

		&.level-2 {
			background: var(--chart-color-0-level-2);
		}

		&.level-3 {
			background: var(--chart-color-0-level-3);
		}
	}

	&.color-1 {
		background: var(--chart-color-1);

		&.level-2 {
			background: var(--chart-color-1-level-2);
		}

		&.level-3 {
			background: var(--chart-color-1-level-3);
		}
	}

	&.color-2 {
		background: var(--chart-color-2);

		&.level-2 {
			background: var(--chart-color-2-level-2);
		}

		&.level-3 {
			background: var(--chart-color-2-level-3);
		}
	}

	&.color-3 {
		background: var(--chart-color-3);

		&.level-2 {
			background: var(--chart-color-3-level-2);
		}

		&.level-3 {
			background: var(--chart-color-3-level-3);
		}
	}

	&.color-4 {
		background: var(--chart-color-4);

		&.level-2 {
			background: var(--chart-color-4-level-2);
		}

		&.level-3 {
			background: var(--chart-color-4-level-3);
		}
	}

	&.color-5 {
		background: var(--chart-color-5);

		&.level-2 {
			background: var(--chart-color-5-level-2);
		}

		&.level-3 {
			background: var(--chart-color-5-level-3);
		}
	}

	&.color-6 {
		background: var(--chart-color-6);

		&.level-2 {
			background: var(--chart-color-6-level-2);
		}

		&.level-3 {
			background: var(--chart-color-6-level-3);
		}
	}

	&:before {
		content: "";
		position: absolute;
		top: 4px;
		left: 4px;
		width: calc(100% - 8px);
		height: calc(100% - 8px);
		background-color: rgba(0, 0, 0, 0.25);
		z-index: 0;
		border-radius: var(--border-radius);
	}
}

.gstc__chart-timeline-grid-row-cell {
	position: relative;
	height: 100% !important;

	.cover {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		border-right: 1px solid var(--color-secondary-step-100);
		border-bottom: 1px solid var(--color-secondary-step-100);

		&.level-0 {
			background: $level-0-bg;
		}

		&.level-1 {
			background: $level-1-bg;
		}

		&.level-2 {
			background: $level-2-bg;
		}

		&.level-3 {
			background: $level-3-bg;
		}
	}
}

// Scroll Bars
.gstc__scroll-bar-inner,
.gstc__scroll-bar-inner:hover,
.gstc__scroll-bar-inner--active {
	background: var(--color-step-200);
}

app-table {
	scrollbar-color: var(--color-step-200);

	table.mat-table {
		//th.mat-header-cell:nth-last-child(n + 3),
		td.mat-cell:nth-last-child(n + 3),
		td.mat-footer-cell:nth-last-child(n + 3) {
			border-right: 1px solid var(--color-secondary-step-200) !important;
		}

		th.mat-header-cell:nth-last-child(n + 3) {
			position: relative;

			.resize-holder {
				cursor: col-resize;
				width: 20px;
				height: 100%;
				position: absolute;
				right: 0;
				top: 0;
				z-index: 1;
				border-right: 1px solid var(--color-secondary-step-200) !important;
			}
		}
	}
}

app-table-basic {
	table.mat-table {
		//th.mat-header-cell:nth-last-child(n + 2),
		td.mat-cell:nth-last-child(n + 2),
		td.mat-footer-cell:nth-last-child(n + 2) {
			border-right: 1px solid var(--color-secondary-step-200) !important;
		}

		th.mat-header-cell:nth-last-child(n + 2) {
			position: relative;
			.resize-holder {
				cursor: col-resize;
				width: 20px;
				height: 100%;
				position: absolute;
				right: 0;
				top: 0;
				z-index: 1;
				border-right: 1px solid var(--color-secondary-step-200) !important;
			}
		}
	}
}

app-table::-webkit-scrollbar-thumb {
	background: var(--color-step-200);
}

table.mat-table {
	border-radius: 4px;
	// overflow: hidden;

	/*tbody {
		// border-left: 1px solid var(--color-secondary-step-100);
		// border-right: 1px solid var(--color-secondary-step-100);
	}*/

	th.mat-header-cell,
	td.mat-cell,
	td.mat-footer-cell {
		padding: 0 15px;
		text-align: left;
		white-space: normal !important;
	}

	th.mat-header-cell:first-of-type,
	td.mat-cell:first-of-type,
	td.mat-footer-cell:first-of-type {
		padding: 0 15px;
		padding-right: 10px;
		// min-width: 150px;
		// width: 300px;
		// max-width: 280px;
	}

	thead th {
		background-color: var(--color-secondary);
		color: white;
	}

	tbody td.mat-cell {
		// background: #fafafa;
		// max-width: 180px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		// &.white {
		// 	background: white;
		// }
		.name-container {
			display: flex;
			align-items: center;

			&.clickable {
				cursor: pointer;
			}

			.name {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.launch {
				padding: 6px 10px 6px 5px;
				color: var(--color-primary);

				&:hover {
					color: var(--color-navy);
				}
			}

			a {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		.expand {
			display: inline-flex;
			width: 16px;
		}

		.brand-budget-icon {
			font-size: 1rem;
			margin-left: 2px;
		}

		&.wrap-text {
			white-space: normal;
			overflow: inherit;
			text-overflow: inherit;
		}

		&.mat-column-action {
			max-width: 30px;
			background: inherit;
			text-overflow: clip;
			pointer-events: none;
			color: var(--color-navy);
			background: transparent !important;

			.bg {
				position: absolute;
				background: white;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				opacity: 0.95;
				z-index: 0;
			}

			i {
				position: relative;
				z-index: 1;
			}

			.mat-icon {
				position: absolute;
				// left: 50%;
				right: 0;
				top: 50%;
				transform: translate3d(-50%, -50%, 0);
			}

			&.linkable {
				cursor: pointer;
				pointer-events: all;

				&:hover {
					opacity: 0.8;
				}
			}
		}
	}
}

.mat-table-sticky {
	background: white;
	background: var(--color-secondary);
	color: white;
	border-bottom: 1px solid black;
}

.table-container-group {
	display: flex;
	background-color: var(--background-color);
}

.table-container-group-sidebar {
	width: 260px;
	height: 100%;
	overflow: auto;
	margin-bottom: 0;
	padding: 14px;
	background: white;
	border-radius: 4px;

	.input-grey-input {
		.mat-form-field-wrapper {
			padding-bottom: 0.3em;
		}
	}

	app-table-filter {
		.wrapper {
			margin: 0 5px;
		}
	}

	position: relative;
}

.table-container-group-tables {
	flex: 1;
	position: relative;
	overflow: hidden;
	border: 1px solid var(--color-secondary-step-150);

	.no-data {
		background: white;
		padding: 20px;
		height: 100%;
	}
}

// Calendar Header
.gstc__list-column-header,
.gstc__chart-calendar,
.gstc__chart-calendar-dates {
	background-color: var(--color-secondary);
	color: white;
}

.gstc__list-column-header {
	z-index: 1;
	position: relative;
}

.gstc__chart-calendar-date--month {
	padding-top: 4px;
}

.gstc__list-column-header-resizer-container {
	display: flex;
	align-items: center;
	text-transform: uppercase;
}

// Remove expander icon from Name column
.gstc__list-column-header-resizer-container--gstcid-name {
	padding-left: 24px;

	.gstc__list-column-row-expander {
		display: none;
	}
}

/**
*	Resizing columns styles
**/
.mat-table {
	&.resizing {
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		cursor: col-resize;
	}

	.mat-cell {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	/*.mat-header-cell {
		// border-right: 1px solid var(--color-secondary-step-600);
	}*/
}
