/* You can add global styles to this file, and also import other style files */
$color-primary: #00aeef;
$color-secondary: #142130;
$color-informative: #00cc8c;
$color-success: #00cc8c;
$color-danger: #ee255c;
$max-width: 1300px;

body {
	--background-color: var(--color-step-50);
	--color-primary: #00aeef;
	--color-primary-darkened: #0397CE;
	--color-secondary: #142130;
	--color-informative: #00cc8c;
	--color-danger: #ee255c;
	--color-warning: #ffb000;
	--color-success: #00cc8c;

	--color-faded-yellow: #ffeecc;
	--color-faded-green: #ccf4e8;

	--color-step-25: #f8f8f8;
	--color-step-50: #f2f2f2;
	--color-step-100: #e6e6e6;
	--color-step-150: #d9d9d9;
	--color-step-200: #cccccc;
	--color-step-250: #bfbfbf;
	--color-step-300: #b3b3b3;
	--color-step-350: #a6a6a6;
	--color-step-400: #999999;
	--color-step-450: #8c8c8c;
	--color-step-500: #808080;
	--color-step-550: #737373;
	--color-step-600: #666666;
	--color-step-650: #595959;
	--color-step-700: #4d4d4d;
	--color-step-750: #404040;
	--color-step-800: #333333;
	--color-step-850: #262626;
	--color-step-900: #191919;
	--color-step-950: #0d0d0d;

	--color-secondary-step-25: #f4f5f7;
	--color-secondary-step-50: #f1f2f3;
	--color-secondary-step-100: #e3e5e7;
	--color-secondary-step-150: #d6d8db;
	--color-secondary-step-200: #c8cbcf;
	--color-secondary-step-250: #bbbec3;
	--color-secondary-step-300: #aeb2b8;
	--color-secondary-step-350: #a1a5ac;
	--color-secondary-step-400: #9499a1;
	--color-secondary-step-450: #878d96;
	--color-secondary-step-500: #7b818b;
	--color-secondary-step-550: #6f7680;
	--color-secondary-step-600: #636a76;
	--color-secondary-step-650: #575f6b;
	--color-secondary-step-700: #4b5461;
	--color-secondary-step-750: #404957;
	--color-secondary-step-800: #353f4d;
	--color-secondary-step-850: #2a3443;
	--color-secondary-step-900: #1f2b39;

	--color-danger-step-25: #FEF4F7;
	--color-danger-step-50: #FDE9EE;
	--color-danger-step-100: #FCDDE6;
	--color-danger-step-150: #FCD2DD;
	--color-danger-step-200: #FBC6D4;
	--color-danger-step-250: #FABBCC;
	--color-danger-step-300: #F9AFC3;
	--color-danger-step-350: #F8A4BB;
	--color-danger-step-400: #F798B2;
	--color-danger-step-450: #F68DA9;
	--color-danger-step-500: #F581A1;
	--color-danger-step-550: #F47698;
	--color-danger-step-600: #F36A90;
	--color-danger-step-650: #F35F87;
	--color-danger-step-700: #F2537E;
	--color-danger-step-750: #F14876;
	--color-danger-step-800: #F03C6D;
	--color-danger-step-850: #EF3165;
	--color-danger-step-900: #ee255c;

	--color-success-step-25: #F2FCF9;
	--color-success-step-50: #E5FAF3;
	--color-success-step-100: #D7F7ED;
	--color-success-step-150: #CAF4E7;
	--color-success-step-200: #BCF2E1;
	--color-success-step-250: #AFEFDB;
	--color-success-step-300: #A2ECD5;
	--color-success-step-350: #94EACF;
	--color-success-step-400: #87E7C9;
	--color-success-step-450: #79E4C3;
	--color-success-step-500: #6CE2BD;
	--color-success-step-550: #5EDFB7;
	--color-success-step-600: #51DCB0;
	--color-success-step-650: #43DAAA;
	--color-success-step-700: #36D7A4;
	--color-success-step-750: #28D49E;
	--color-success-step-800: #1BD198;
	--color-success-step-850: #0ECF92;
	--color-success-step-900: #00cc8c;


	--chart-color-0: rgba(0, 174, 239, 1);
	--chart-color-0-level-2: rgba(0, 174, 239, 0.7);
	--chart-color-0-level-3: rgba(0, 174, 239, 0.5);

	--chart-color-1: rgba(0, 209, 133, 1);
	--chart-color-1-level-2: rgba(0, 209, 133, 0.7);
	--chart-color-1-level-3: rgba(0, 209, 133, 0.5);

	--chart-color-2: rgba(255, 1, 89, 1);
	--chart-color-2-level-2: rgba(255, 1, 89, 0.7);
	--chart-color-2-level-3: rgba(255, 1, 89, 0.5);

	--chart-color-3: rgba(138, 6, 238, 1);
	--chart-color-3-level-2: rgba(138, 6, 238, 0.7);
	--chart-color-3-level-3: rgba(138, 6, 238, 0.5);

	--chart-color-4: rgba(238, 113, 6, 1);
	--chart-color-4-level-2: rgba(238, 113, 6, 0.7);
	--chart-color-4-level-3: rgba(238, 113, 6, 0.5);

	--chart-color-5: rgba(100, 100, 100, 1);
	--chart-color-5-level-2: rgba(100, 100, 100, 0.7);
	--chart-color-5-level-3: rgba(100, 100, 100, 0.5);

	--chart-color-6: rgba(7, 0, 45, 1);
	--chart-color-6-level-2: rgba(7, 0, 45, 0.7);
	--chart-color-6-level-3: rgba(7, 0, 45, 0.5);




	--border-radius: 4px;

	--font-heading: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
	--font-paragraph: Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;

	margin: 0;
	background-color: var(--background-color);
}
