app-auth-okta {
	.okta-sign-in-header.auth-header {
		display: none;
	}

	.auth-container {
		padding: 0 !important;
		margin: 0 auto !important;
	}
}
