// @import './theme/theme';
@import '~@nudgelabs/geometry-design-system-lib/src/lib/styles/main';
@import '~@vmlyr/geometrics/src/styles/styles';
@import '~@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

@import './theme/variables';

@import "~angular2-multiselect-dropdown/themes/default.theme.css";
@import '~tippy.js/dist/tippy.css';
@import '~swiper/swiper-bundle.css';
@import './theme/global';
@import './theme/layout';
@import './theme/typography';
@import './theme/table';
@import './theme/simple-table';
@import './theme/details-page';
@import './theme/inputs';
@import './theme/modals';
@import './theme/popover';
@import './theme/utils';
@import './theme/tools/geometrics';
@import './theme/okta';
@import './theme/vendor-model';
@import '~gantt-schedule-timeline-calendar/dist/style.css';
@import "~angular2-multiselect-dropdown/themes/default.theme.css";
@import "~tippy.js/dist/tippy.css";
@import "./theme/documentation";