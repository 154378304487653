html,
body {
	height: 100%;
}

a {
	cursor: pointer;
}

hr {
	margin: 0rem 0;
}

small {
	font-size: 85%;
}

.mat-menu-panel {
	min-height: 34px !important;
}

.mat-menu-content {
	button.active {
		color: var(--color-primary);
	}
}

.mat-menu-item .mat-icon {
	font-size: 14px;
	height: 14px;
	width: 6px;
}

.mat-tab-body-wrapper,
.mat-tab-body-content {
	overflow: hidden !important;
}

.mat-tooltip {
	white-space: pre-line;
}

.tippy-box {
	background: var(--color-secondary) !important;
}

button.mat-mini-fab.button-extra-small,
button.mat-mini-fab.button-extra-small .mat-button-wrapper {
	line-height: 20px;
}

button.mat-mini-fab.button-small {
	line-height: 31px;
}

button.mat-flat-button.secondary,
button.mat-mini-fab.secondary,
button.mat-fab.secondary {
	background: var(--color-secondary);
	color: white;

	&:hover {
		background: var(--color-secondary-step-700);
		color: white;

		.uil {
			color: white;
		}
	}

	&.icon-left {
		padding: 6px 15px 6px 33px;
	}
}

button.mat-flat-button.tertiary,
button.mat-mini-fab.tertiary,
button.mat-fab.tertiary {
	background: var(--color-step-100);
	color: var(--color-secondary);

	&:hover {
		background: var(--color-secondary);
		color: white;

		.uil {
			color: white;
		}
	}

	&.icon-left {
		padding: 6px 15px 6px 33px;
	}
}

button.mat-stroked-button.tertiary {
	border-color: var(--color-step-100);
	color: var(--color-secondary);

	&:hover {
		border-color: var(--color-secondary);
	}

	&.icon-left {
		padding: 6px 15px 6px 33px;
	}

	&.icon-right {
		padding: 6px 33px 6px 15px;
	}
}

button.mat-flat-button.success,
button.mat-mini-fab.success,
button.mat-fab.success {
	background: var(--color-step-100);
	color: var(--color-secondary);

	&:hover {
		background: var(--color-success);
		color: white;

		.uil {
			color: white;
		}
	}

	&.icon-left {
		padding: 6px 15px 6px 33px;
	}

	&.bold {
		background: var(--color-success);
		color: white;
	}
}

button.mat-flat-button.warning,
button.mat-mini-fab.warning,
button.mat-fab.warning {
	background: var(--color-step-100);
	color: var(--color-secondary);

	&:hover {
		background: var(--color-warning);
		color: white;

		.uil {
			color: white;
		}
	}

	&.icon-left {
		padding: 6px 15px 6px 33px;
	}

	&.bold {
		background: var(--color-warning);
		color: white;
	}
}

.mat-snack-bar-container {
	&.mat-snack-bar-center {
		right: 50%;
		transform: translate3d(50%, 0, 0) scale(1) !important;

		.mat-simple-snackbar {
			justify-content: center;
		}
	}

	&.secondary {
		background-color: var(--color-secondary);
		color: white;
	}
}

button.mat-flat-button .mat-button-wrapper,
button.mat-mini-fab .mat-button-wrapper,
button.mat-fab .mat-button-wrapper {
	padding-top: 0px;
	padding-bottom: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: inherit;
}

.output-container {
	gap: 10px;

	button.mat-flat-button .mat-button-wrapper,
	button.mat-mini-fab .mat-button-wrapper,
	button.mat-fab .mat-button-wrapper {
		height: auto;
	}
}

button.mat-button.button-small,
button.mat-icon-button.button-small,
button.mat-stroked-button.button-small,
button.mat-flat-button.button-small {
	padding: 0 20px;
}

button.mat-button.button-extra-small,
button.mat-icon-button.button-extra-small,
button.mat-stroked-button.button-extra-small,
button.mat-flat-button.button-extra-small {
	padding-top: 0;

	&.button-tab {
		// border: 1px solid var(--color-step-50);
		background-color: var(--color-step-50);
	}
}

button.mat-flat-button.mat-tertiary:hover,
button.mat-mini-fab.mat-tertiary:hover,
button.mat-fab.mat-tertiary:hover {
	background: var(--color-secondary);
}

button.mat-flat-button,
button.mat-mini-fab,
button.mat-fab,
.mat-chip,
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
	background-color: var(--color-primary);
}

.mat-select-arrow,
.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
	color: var(--color-primary);
}

button.mat-icon-button {
	color: var(--color-primary);
}

.mat-chip:hover,
button.mat-flat-button:hover,
button.mat-mini-fab:hover,
button.mat-fab:hover {
	background-color: var(--color-primary-darkened);
}

.mat-standard-chip .mat-chip-remove,
.mat-standard-chip .mat-chip-trailing-icon {
	margin-top: 5px;
}

.mat-standard-chip {
	height: auto !important;
}

table.mat-table tbody td.mat-cell i {
	opacity: 1;
	color: black;
	margin-left: 0;
}

.mat-tab-label {
	min-width: 150px !important;
}

.mat-secondary .mat-tab-label-container .mat-tab-label {
	color: var(--color-secondary-step-400);
}

mat-checkbox {
	&.small {
		.mat-checkbox-layout {
			font-size: 1.4rem;
		}

		.mat-checkbox-inner-container {
			width: 14px;
			height: 14px;
		}

		.mat-checkbox-label {
			padding-top: 4px;
		}
	}
}

[class^="uil-"],
[class*=" uil-"] {
	&.inline {
		font-size: inherit;
	}
}

.mat-dialog-container {
	min-width: auto;

	.mat-dialog-content {
		padding-bottom: 20px;
	}

	.mat-dialog-actions {
		padding: 0;
	}
}

i.inline {
	font-size: inherit !important;
}

.button-suggestion-small {
	outline: none;
	border: none;
	border-radius: var(--border-radius);
	padding: 3px 10px;
	margin-right: 6px;
	margin-bottom: 6px;
	font-size: 1.2rem;
	cursor: pointer;

	&:hover {
		background-color: var(--color-secondary);
		color: white;
	}

	&.hover-primary:hover {
		background-color: var(--color-primary);
		color: white;
	}

	&.hover-tertiary:hover {
		background-color: var(--color-step-200);
		color: var(--color-secondary);
	}
}

.mat-select-panel {
	border: 1px solid var(--color-secondary-step-200);
	box-shadow: 0 0 5px var(--color-secondary-step-200);
	background-color: white;
}

.mat-paginator-container {
	@media only screen and (max-width: 600px) {
		justify-content: center !important;
		margin-top: 10px !important;
	}
}

.cdk-overlay-pane {
	&.fullscreen {
		.mat-dialog-container {
			width: 90vw !important;
			max-height: 84vh;
		}

		&.min-height {
			min-height: 84vh;
		}

		&.stretch {
			.mat-dialog-container > * {
				height: 100%;
				display: flex;
				flex-direction: column;
			}

			.mat-dialog-content {
				flex: 1;
			}
		}
	}

	&.background-color {
		.mat-dialog-container {
			background: var(--background-color) !important;
		}
	}

	&.max-width-xlg {
		.mat-dialog-container {
			max-width: 1400px;
		}
	}

	&.max-width-lg {
		.mat-dialog-container {
			max-width: 1000px;
		}
	}

	&.max-width-md {
		.mat-dialog-container {
			max-width: 650px;
		}
	}

	&.max-width-sm {
		.mat-dialog-container {
			max-width: 480px;
		}
	}
}

/**
*  Multi-line select dropdowns
**/
.multi-line-option {
	line-height: unset !important;
	height: unset !important;

	.mat-option-text {
		padding: 7px 0;
		white-space: normal;
	}

	.mat-select-value-text {
		white-space: normal;
	}

	.mat-line {
		line-height: 1em;
	}

	p {
		font-size: 1.2rem;
		font-weight: 300;
	}
}
// ************

.mat-select-disabled {
	.mat-form-field-label {
		color: rgb(199, 200, 202) !important;
	}

	.mat-select-arrow {
		opacity: 0;
	}
}

.bubble {
	border: 1px solid var(--color-step-100);
	border-radius: 6px;
	padding: 12px 15px;
	display: flex;
	flex-direction: column;
	justify-content: center;

	.title {
		font-family: var(--font-paragraph);
		font-weight: 300;
		margin-bottom: 0;

		strong {
			font-family: var(--font-heading);
		}
	}

	.inline-list {
		line-height: 1;
		margin-top: 8px;
		display: flex;
		flex-wrap: wrap;

		> span {
			border: 1px solid rgba(0, 0, 0, 0.3);
			padding: 4px 7px 3px;
			border-radius: 6px;
			font-size: 1.4rem;
			margin-right: 10px;

			.amount-difference {
				margin: 0 0 0 4px;
				line-height: 1rem;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}

	&.borderless {
		border: none;
	}
}

.popover-content .bubble .inline-list > span {
	margin-bottom: 8px;
}

.amount-difference {
	font-weight: 500;
	font-size: 1.1rem;
	line-height: 1.1rem;
	color: var(--color-success);
	margin-top: 4px;

	&.neutral {
		color: inherit;
	}

	&.negative {
		color: var(--color-danger);
	}
}

.loading {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	font-size: 0.7rem;
	color: #999;

	.logo {
		margin-top: 10px;
	}

	.error {
		font-size: 1.8rem;
		text-align: center;

		a {
			color: var(--color-secondary);
			font-weight: bold;
		}
	}
}

span.status {
	background: var(--color-faded-yellow);
	color: var(--color-secondary);
	border-radius: 4px;
	padding: 5px 12px;
	display: flex;
	align-items: center;
	font-size: 1.2rem;
	line-height: 1.2rem;
	font-weight: 400;

	&[color="secondary"] {
		background: var(--color-secondary);
		color: white;
	}
}

.covered {
	position: relative;
	pointer-events: none;

	&:after {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		border-radius: 6px;
		background: rgba(255, 255, 255, 0.9);
	}

	&.half-cover {
		&:after {
			background: rgba(255, 255, 255, 0.6);
		}
	}
}

button.loading-spinner {
	&:before {
		content: "";
		box-sizing: border-box;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 20px;
		height: 20px;
		margin-top: -10px;
		margin-left: -10px;
		border-radius: 50%;
		border: 2px solid var(--color-secondary);
		border-top-color: transparent;
		animation: spinner 0.8s linear infinite;
	}

	&.white-spinner {
		&:before {
			border-color: white;
			border-top-color: transparent;
		}
	}
}

@keyframes spinner {
	to {
		transform: rotate(360deg);
	}
}

span.badge {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	// height: 17px;
	// width: 17px;
	padding: 5px 7px;
	margin-left: 7px;
	background: var(--color-step-100);
	color: black;
	border-radius: 50%;
	font-size: 1.1rem;
	line-height: 1.1rem;
	position: relative;

	&.primary {
		background: var(--color-primary);
		color: white;
	}

	&.secondary {
		background: var(--color-secondary);
		color: white;
	}

	&.success {
		background: var(--color-success);
		color: white;
	}

	&.danger {
		background: var(--color-danger);
		color: white;
	}

	&.warning {
		background: var(--color-warning);
		color: white;
	}
}

snack-bar-container {
	&.primary {
		background: var(--color-primary);
		color: white;
	}
	&.secondary {
		background: var(--color-secondary);
		color: white;
	}
	&.success {
		background: var(--color-success);
		color: white;
	}
	&.danger {
		background: var(--color-danger);
		color: white;
	}
}

.bubble-notification {
	border: 1px solid var(--color-step-300);
	padding: 10px;
	border-radius: 5px;
	margin-bottom: 25px;
}

.dropdown-list.tagToBody {
	position: fixed;
	top: unset !important;
	left: unset !important;
}

.toggle-title {
	font-size: 12px;
	margin-left: 5px !important;
}

.mat-chip-list-wrapper {
	margin: 0px !important;
}

.markdown-content {
	a {
		color: var(--color-primary);
	}
}

.table-drawer {
	height: 40%;
	background: white;
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	max-height: 450px;
	padding: 0 20px;
	border: 1px solid var(--color-step-250);
	border-bottom-left-radius: var(--border-radius);
	border-bottom-right-radius: var(--border-radius);
	padding-bottom: 5px;
	box-shadow: 0px -10px 13px rgb(0 0 0 / 25%);
	display: flex;
	flex-direction: column;

	.title-row {
		padding: 10px 10px 0 10px;
		margin-bottom: 10px;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin-bottom: 0;
		}
	}

	.table-drawer-body {
		flex: 1;
		overflow-y: auto;
		padding: 0 20px;
	}
}

.flex-direction-column {
	flex-direction: column;
}

.justify-content-space-between {
	justify-content: space-between;
}

.align-items-center {
	align-items: center;
}

.z-index-100 {
	z-index: 100 !important;
}

.pointer-events-none {
	pointer-events: none !important;
}
