table.simple-table {
	width: 100%;
	border-radius: 5px;
	overflow: hidden;
	table-layout: fixed;
	background: transparent;

	tbody {
		border: 1px solid var(--color-secondary-step-100);

		// Hack for this weird table margin
		border-left: 2px solid var(--color-secondary-step-100);

		tr {
			&:nth-child(even) {
				background: var(--color-secondary-step-50);

				.input-cell {
					background: var(--color-secondary-step-150);
				}
			}

			&.active {
				td {
					background: var(--color-secondary);
					color: white;

					* {
						color: white;
					}
				}
			}

			.input-cell {
				&.danger {
					background: var(--color-danger-step-850);
					color: black;
				}

				&.success {
					background: var(--color-success-step-850);
					color: black;
				}
			}
		}
	}

	tr {
		border-bottom: 1px solid var(--color-secondary-step-100);
	}

	th, th input {
		padding: 5px;
		text-align: left;
		font-weight: 500;
		font-size: 1.2rem;
		color: var(--color-secondary-step-300);
		border: none;
	}

	thead th {
		padding: 0 5px 0;
		vertical-align: middle;
		background-color: #ffffff;
		color: #7f7f7f;

		&.header-invert {
			background-color: var(--color-secondary);
			color: white;
		}
	}

	th input {
		text-align: center;
	}

	td.mat-cell {
		font-weight: 500;
		padding: 8px 10px;

		input {
			width: 100%;
		}

		button i {
			margin: 0;
			opacity: 1;
			color: white;
		}
	}

	th,
	td {
		position: relative;
		z-index: 9;
		background-color: white;

		&.fixed-right {
			width: 130px;
			text-align: right;
		}

		&.no-bg {
			background: transparent;
		}
	}

	td.name {
		input {
			text-align: left;
			border: none;
		}
	}

	td.cdk-column-Actions {
		text-align: right;
    	padding-right: 20px;
	}

	.header {
		border: none;

		th {
			padding: 0 5px;
			z-index: 10;
		}
	}

	.period-title {
		font-size: 1.4rem;
		color: #7f7f7f;
	}

	.faded {
		opacity: 0.6;
	}

	.input-cell {
		background: var(--color-secondary-step-100);
		border: 1px solid rgba(0, 0, 0, 0.1);

		input {
			border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		}
	}

	.success {
		color: var(--color-success);
	}

	.error {
		color: var(--color-danger);
	}

	td > input {
		border: 0;
		border-bottom: 1px solid #ddd;
		width: 100%;
		background: transparent;
		text-align: center;
		font-size: 1.5rem;
		font-weight: 700;
	}

	.total,
	.appended-row {
		background-color: var(--color-secondary);
		color: white;
		border: none;

		td {
			font-weight: 700;
			color: white;

			input {
				color: white;
			}
		}
	}

	.remove {
		position: absolute;
		bottom: -10px;
		left: 50%;
		transform: translateX(-50%);
		display: none;
		pointer-events: none;

		&.right {
			bottom: auto;
			left: auto;
			right: 0px;
			top: 46%;
			transform: translateY(-50%);
		}

		button {
			font-size: 1rem;
			height: 20px;
			width: 20px;
			line-height: 0;
			z-index: 999;

			&:hover {
				background: var(--color-danger);
			}
		}
	}

	td:hover, th:hover {
		.remove {
			display: block;
			pointer-events: all;
		}
	}

	a {
		&:hover {
			text-decoration: underline;
		}
	}

	tr.element-row {
		&.expanded {
			border-bottom: none;
		}
	}

	/*
		Expanded element row styles
	*/
	tr.expanded-detail-row {
		height: 0;

		td {
			border: none;
			padding: 0;
		}
	}

	.expanded-element-row td {
		border-bottom-width: 0;
	}

	.expanded-element-detail {
		overflow: hidden;
		display: flex;
		justify-content: space-between;

		.code-block {
			width: 45%;
		}

		pre {
			border-radius: 6px;
			overflow: hidden;
			max-height: 300px;
			overflow: auto;
			background: var(--color-secondary-step-100);
			color: var(--color-secondary);

			code {
				background: inherit;
				font-size: inherit;
			}
		}
	}

}
