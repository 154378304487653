:root {
	--color-primary-rgb: 0, 174, 239;
	--color-primary-hover: #008bbf;
	--color-primary-hover-rgb: 0, 139, 191;
	--color-doc-orange: #f28c28;
	--color-doc-purple: #5700ff;
}

@import "~prismjs/plugins/toolbar/prism-toolbar.css";
@import "~prismjs/themes/prism-okaidia";

.documentation-highlight {
	position: relative;
	border: 1px dashed var(--color-primary);
	transition: border 0.3s, box-shadow 0.3s;
	cursor: pointer;
	box-shadow: 0 0 5px rgba(var(--color-primary-rgb), 0.3);
}

@keyframes pulseBorder {
	0% {
		box-shadow: 0 0 5px rgba(var(--color-primary-rgb), 0.1), 0 0 0 0 rgba(var(--color-primary-rgb), 0.7);
	}
	70% {
		box-shadow: 0 0 5px rgba(var(--color-primary-rgb), 0.1), 0 0 8px 8px rgba(var(--color-primary-rgb), 0);
	}
	100% {
		box-shadow: 0 0 5px rgba(var(--color-primary-rgb), 0.1), 0 0 0 0 rgba(var(--color-primary-rgb), 0);
	}
}

.documentation-highlight::before {
	content: "";
	position: absolute;
	top: 2px;
	left: 2px;
	right: 2px;
	bottom: 2px;
	border-radius: 5px;
	border: 1px solid transparent;
	transition: all 0.3s;
	z-index: 1;
}

.documentation-highlight-active::before {
	animation: pulseBorder 1.8s infinite;
}

// Global dialog styles
.doc-dark-container,
.doc-light-container {
	width: 100%;
	padding: 16px;
	border-radius: 5px;
}

.doc-light-container {
	background-color: #f9f9f9;
}

.doc-dark-container {
	background-color: #333;
	color: #fff;
}

.doc-section-separator {
	height: 24px;
}

.doc-paragraph-separator {
	height: 12px;
}

// Titles
.doc-main-title {
	font-weight: bold;
	font-size: 24px;
	margin-bottom: 12px;
	margin-top: 36px;
}

.doc-container-title {
	font-weight: bold;
	font-size: 20px;
	margin-bottom: 8px;
	margin-top: 24px;
}

.doc-container-subtitle {
	font-weight: bold;
	font-size: 16px;
	margin-bottom: 8px;
	margin-top: 12px;
}

// Decorator
.doc-decorator {
	display: flex;
	flex-wrap: wrap;
	color: #43a047;
	font-weight: 400;

	.decorator-mark {
		margin-right: 4px;
	}

	.decorator-name {
		color: #000;
	}

	.decorator-type {
		color: #333;
		margin-left: 4px;
		display: flex;
	}

	.decorator-value {
		color: #000;
		margin-left: 4px;
		font-weight: 300;
		white-space: pre-wrap;
	}
}

// Section for properties
.doc-property-list {
	.property:not(:last-child) {
		margin-bottom: 5px;

		.property-name {
			color: #0288d1; // Blue for property names
		}

		.property-type {
			color: #000; // Black for property types
		}
	}
}

// Comment
.doc-comment {
	color: #8292a2;
	font-style: italic;
	margin-left: 4px;
	font-weight: 500;
	text-decoration: underline;
}

// Class name e.g. export class MyClass
.doc-class-name {
	color: var(--color-step-500);
	font-weight: 700;
	margin-bottom: 8px;
}

// Component row with copy button
.doc-component-row {
	display: flex;
	align-items: center;

	& .uil-copy {
		cursor: pointer;
		font-size: 22px;
		margin-left: 12px;
	}

	&__name {
		color: var(--color-doc-orange);
		font-weight: 700;
		margin-right: 6px;
	}
}

// Dialog title row with copy button
.doc-dialog-title-row {
	display: flex;
	align-items: center;

	& .uil-copy {
		cursor: pointer;
		font-size: 22px;
		margin-left: 12px;
	}
}

.doc-component-row-has-details {
	& .doc-component-row__name {
		cursor: pointer;
		text-decoration: underline;
	}
}

// Code display container
.code-display-container {
	.mat-expansion-panel-body {
		padding: 0;
	}

	.mat-expansion-panel-header {
		background-color: #333;
		padding: 8px 16px;
		color: var(--color-faded-yellow);
		font-weight: 700;
		border: 1px solid var(--color-faded-yellow);
		border-radius: 5px;

		.mat-expansion-indicator {
			color: var(--color-faded-yellow);
		}
	}

	.mat-expansion-panel-header.mat-expanded:hover,
	.mat-expansion-panel-header.mat-expanded:focus {
		background-color: #333;
	}
}

// Unordered list
.doc-unordered-list {
	list-style-type: disc;
	padding-left: 12px;
	margin-bottom: 12px;

	& li {
		margin-left: 12px;
	}
}

//  Clickable Interface, Clickable Component
.doc-clickable-interface,
.doc-clickable-component,
.doc-clickable-flow {
	cursor: pointer;
	text-decoration: underline;
	font-weight: 600;
}
.doc-clickable-interface {
	color: var(--color-primary);
}
.doc-clickable-component {
	color: var(--color-doc-orange);
}
.doc-clickable-flow {
	color: var(--color-doc-purple);
}

// Default Paragraph
.doc-default-paragraph {
	font-weight: 300;
	font-size: 16px;
	margin-bottom: 12px !important;
}

// Dynamically added classes
.documentation-file-location {
	background-color: #e8e8e8;
	color: #333333;
	padding: 5px;
	border-radius: 5px;
	font-weight: 500;
	overflow-wrap: break-word;
	cursor: pointer;
}