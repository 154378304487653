.vendor-model {
	.row {
		display: flex;
		margin: 14px 0;

		@media only screen and (max-width: 768px) {
			flex-wrap: wrap;
			justify-content: center;
		}
	}

	.info-container {
		width: 210px;
		padding: 25px 40px 10px 0;
		text-align: right;

		.percentage {
			font-size: 4rem;
			color: var(--chart-color-0);
		}

		.progress {
			border-radius: 50px;
			background-color: var(--color-step-200);
			width: 130px;
			height: 18px;
			margin-bottom: 9px;
			margin-left: auto;
			position: relative;
			overflow: hidden;

			.fill {
				position: absolute;
				top: 0;
				bottom: 0;
				background: var(--chart-color-0);
			}
		}

		.description {
			font-size: 1.1rem;
		}
	}

	.vendors-container {
		border-radius: 5px;
		border: 1px solid var(--color-step-200);
		padding: 22px 25px;
		flex: 1;
		display: flex;
		flex-wrap: wrap;

		@media only screen and (max-width: 768px) {
			flex: 1 !important;
		}
	}

	.vendor {
		border-radius: 5px;
		border: 1px solid var(--color-step-100);
		width: calc(25% - 14px);
		min-width: max-content;
		padding: 8px;
		margin-right: 14px;
		margin-bottom: 7px;
		margin-top: 7px;
		transition: all 300ms;
		cursor: pointer;

		.name-container {
			width: 100%;
			text-align: center;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				height: 100%;
				width: 100%;
				object-fit: contain;
				padding-bottom: 8px;
				border-radius: 5px;
				max-width: 80%;
			}

			h4 {
				margin: 0;
			}
		}

		.metadata {
			display: flex;
			font-size: 1.1rem;
			border-top: 1px solid var(--color-step-200);

			.spend,
			.percent {
				flex: 1;
				text-align: center;
				padding: 5px;
			}

			.spend {
				font-weight: 700;
				border-right: 1px solid var(--color-step-200);
			}
		}

		&:hover {
			border-color: var(--color-step-500);
		}
	}

	.row-70 {
		.info-container {
			.percentage {
				font-size: 6rem;
			}

			.progress .fill {
				left: 0;
				right: 30%;
			}
		}
	}

	.row-20 {
		.info-container {
			.percentage,
			.progress {
				opacity: 0.85;
			}

			.percentage {
				font-size: 5rem;
			}

			.progress .fill {
				left: 70%;
				right: 10%;
			}
		}

		.vendors-container {
			flex: 0.85;

			.vendor {
				.name-container {
					height: 30px;

					h4 {
						font-size: 1.4rem;
					}
				}
			}
		}
	}

	.row-10 {
		.info-container {
			.percentage,
			.progress {
				opacity: 0.5;
			}

			.percentage {
				font-size: 3.5rem;
			}

			.progress .fill {
				left: 90%;
				right: 0;
			}
		}

		.vendors-container {
			flex: 0.7;

			.vendor {
				width: calc(33% - 14px);

				.name-container {
					height: 25px;

					h4 {
						font-size: 1.3rem;
					}
				}
			}
		}
	}
}
